<template>
  <div
    :id="targetField"
    uk-modal
    esc-close="true"
    bg-close="true"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
      />
      <img
        :src="targetPreview"
        alt="image preview"
      >
      <button
        class="uk-button uk-button-primary uk-margin-top"
        type="button"
        @click="downloadPicture(targetPreview, targetPreview.substring(targetPreview.lastIndexOf('-') + 1))"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    targetField: {
      required: true,
      type: String
    },
    targetPreview: {
      required: true,
      type: String
    }
  },
  methods:{
    downloadPicture(url, fileName='idCard'){
      var xhr = new XMLHttpRequest()
      xhr.open("GET", url, true)
      xhr.responseType = "blob"
      xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL
        var imageUrl = urlCreator.createObjectURL(this.response)
        var tag = document.createElement('a')
        tag.href = imageUrl
        tag.download = fileName
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.send()
    }
  }
}
</script>
