<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              User ID
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama User
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Jabatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.position"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Role
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.role"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            v-show="dataUser.role !== 'Super Admin' && dataUser.role !== 'PPL'"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Peternakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.farm"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              No. Handphone
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span class="uk-form-icon">+62 </span>
                <input
                  v-model="dataUser.phone"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Email
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.email"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Password
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span class="uk-form-icon uk-form-icon-flip"><img
                  :src="`${images}/icon/password-lock.svg`"
                  alt=""
                ></span>
                <input
                  class="uk-input"
                  type="password"
                  value="password"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Foto KTP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-width-1-1 uk-input uk-flex uk-flex-between">
                <span class="truncate">
                  {{ dataUser.identity_card.substring(dataUser.identity_card.lastIndexOf('-') + 1) || '-' }}
                </span>
                <img
                  v-if="dataUser.identity_card"
                  v-lazy="`${images}/icon/eye-line.svg`"
                  alt="icon action"
                  class="icon-eye"
                  @click="handlePreviewImage(dataUser.identity_card)"
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              No. KTP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.identity_number"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="dataUser.address"
                class="uk-textarea"
                rows="3"
                disabled
              />
            </div>
          </div>
          <div
            v-show="dataUser.role === 'Mitra / Owner' || dataUser.role === 'Anak Kandang'"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              NPWP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.tax_id_number"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Gabung
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.join_date"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <label-status :status="dataUser.status" />
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Terakhir Diubah
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.updated_at"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Diubah Oleh
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataUser.updated_by"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="showDeleteConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/trash-white.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Hapus
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToEdit"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Ubah
          </button>
        </div>
      </div>
    </div>
    <div class="uk-card uk-card-default kandang-card uk-margin-top">
      <h1
        class="text-medium"
        style="color: #1F2E28; font-size: 14px;"
      >
        Log History
      </h1>
      <div class="uk-margin-top">
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th
                  class="table-header uk-table-small uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Detail Perubahan</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Sebelum</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Sesudah</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Tanggal Diubah</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Diubah Oleh</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Status</span>
                </th>
              </tr>
            </thead>
            <template>
              <tbody v-if="dataLogHistory.data.user_log_history.length > 0">
                <tr
                  v-for="(item, i) in dataLogHistory.data.user_log_history"
                  :key="i"
                >
                  <td>{{ item.changes_detail == 'PHONE NUMBER' ? 'No. Handphone' : item.changes_detail || "-" }}</td>
                  <td>{{ item.before || "-" }}</td>
                  <td>{{ item.after || "-" }}</td>
                  <td>{{ dateParanjeCompleteString(item.created_at) || "-" }}</td>
                  <td>{{ item.user_name || "-" }}</td>
                  <td
                    v-if="item.status=='SUCCESS'"
                  >
                    <div class="label-green">
                      Berhasil
                    </div>
                  </td>
                  <td
                    v-else
                  >
                    <div class="label-red">
                      Tidak Berhasil
                    </div>
                  </td>
                </tr>
              </tbody>
              <empty-table
                v-else
                :colspan="6"
              />
            </template>
          </table>
        </div>
      </div>
    </div>
    <modal-preview
      :target-preview="blob"
      :target-field="'modal-preview-submit-verify'"
    />
    <pagination
      :total-data="dataLogHistory.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="dataLogHistory.meta.current_page"
      :current-limit="dataLogHistory.meta.limit"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateParanjeCompleteString } from '@/utils/formater'
import LabelStatus from '@/components/globals/LabelStatus'
import ModalPreview from '@/components/globals/modal/ModalPreviewWithDownload'
import EmptyTable from '@/components/globals/table/EmptyTable'
import Pagination from "@/components/globals/Pagination"

export default {
  components: {
    LabelStatus,
    ModalPreview,
    EmptyTable,
    Pagination
  },
  props: {
    dataUser: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      blob: '',
      logHistoryData: {}
    }
  },
  computed : {
    ...mapGetters({
      dataLogHistory: 'user/logHistoryUser',
      metaLogHistoryUser: 'user/metaLogHistoryUser'
    })
  },
  watch: {
    async metaLogHistoryUser() {
      this.is_loading = true
      await this.userLogHistory(this.$route.params.id+'?limit='+this.metaLogHistoryUser.limit+"&page="+this.metaLogHistoryUser.page)
      this.is_loading = false
    },
    dataUser() {
      this.dataUser.join_date = dateParanjeCompleteString(this.dataUser.join_date)
      this.dataUser.updated_at = dateParanjeCompleteString(this.dataUser.updated_at)
    }
  },
  async mounted() {
    await this.userLogHistory(this.$route.params.id+'?limit='+this.metaLogHistoryUser.limit+"page="+this.metaLogHistoryUser.page)
    this.is_loading = false
  },
  methods: {
    handlePreviewImage(target) {
      this.blob = target.includes('base64') ? target : this.image_url + target
      window.UIkit.modal('#modal-preview-submit-verify').show()
    },
    ...mapActions({
      userLogHistory: 'user/getUserDetail'
    }),
    dateParanjeCompleteString(time){
      return dateParanjeCompleteString(time)
    },
    ...mapMutations({
      setModalDelete: 'user/SET_MODAL_DELETE',
      setMetaLogHistoryUser: 'user/SET_META_LOG_HISTORY_USER'
    }),
    goToBack() {
      this.$router.push(`/admin/user`)
    },
    goToEdit() {
      this.$router.push(`/admin/user/edit/${this.dataUser.id}`)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataUser)
    },
    changeLimit(e) {
      this.setMetaLogHistoryUser({
        ...this.metaLogHistoryUser,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaLogHistoryUser({
        ...this.metaLogHistoryUser,
        page: value
      })
    }
  }
}
</script>

<style scoped>
.icon-eye {
  padding: 8px;
  cursor: pointer;
}
.label-green {
  border-radius: 8px;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.2);
  color: #14B614 !important;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.label-red {
  border-radius: 8px;
  background: rgba(237, 94, 94, 0.16);
  border: 1px solid rgba(237, 94, 94, 0.2);
  color: #ED5E5E;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
</style>
