<template>
  <div
    :class="status == 'rejected' ? 'status-inactive' : status == 'completed' ? 'status-active' : status == 'draft' ? 'status' : status == 'in_transit' ? 'transit' : '-' || '-'"
  >
    {{
      status == 'rejected' ? 'Canceled' : status == 'completed' ? 'Delivered' : status == 'draft' ? 'On Process' : status == 'in_transit' ? 'In Transit' : '-' || '-' 
    }}
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    status: { required: true }
  }
}
</script>

<style scoped>
.status-active {
  border-radius: 8px;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.2);
  color: #14B614;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.status-inactive {
  border-radius: 8px;
  background: rgba(237, 94, 94, 0.16);
  border: 1px solid rgba(237, 94, 94, 0.2);
  color: #ED5E5E;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.status {
  border-radius: 8px;
  background: rgba(237, 165, 92, 0.16);
  border: 1px solid rgba(237, 165, 92, 0.16);
  color: #EDA55C;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.transit {
  border-radius: 8px;
  background: rgba(45, 153, 255, 0.16);
  border: 1px solid rgba(45, 153, 255, 0.16);
  color: #2D99FF;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
</style>
