<template>
  <active v-if="status === true" />
  <inactive v-else-if="status === false" />
  <succeeded
    v-else-if="status !== false && status !== true"
    :status="status"
  />
</template>

<script>
import Active from '@/components/globals/label/active'
import Inactive from '@/components/globals/label/inactive'
import Succeeded from '@/components/globals/label/succeeded'


export default {
  components: {
    Active,
    Inactive,
    Succeeded
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    status: { required: true }
  }
}
</script>
