import { render, staticRenderFns } from "./inactive.vue?vue&type=template&id=4d897dfc&scoped=true"
var script = {}
import style0 from "./inactive.vue?vue&type=style&index=0&id=4d897dfc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d897dfc",
  null
  
)

export default component.exports